<template>
  <validation-observer ref="brandForm">
    <b-form>
      <b-row>
        <b-col cols="8">
          <b-card>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Brand Name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Brand Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <div class="media-selecion">
                  <div>
                    <MediaUploader
                      @mediaSelected="handleLogoSelect"
                      text="Select Logo"
                      :isMultiple="false"
                    />
                  </div>
                </div>
              </b-col>
              <b-col cols="12">
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="createBrand"
                >
                  <b-spinner v-if="creatingBrand" small />
                  Create Brand
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card v-if="logo.image">
            <div class="text-center">
              <b-img v-if="logo.image" fluid :src="logo.image"></b-img>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BContainer,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BCard,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import MediaUploader from "../../components/MediaUploader.vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";

export default {
  components: {
    BContainer,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BCard,
    MediaUploader,
    BImg,
    BSpinner,
  },
  data() {
    return {
      name: "",
      logo: {
        image: "",
        alt: "",
      },
      required,
      creatingBrand: false,
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    createBrand() {
      this.$refs.brandForm.validate().then((success) => {
        if (success) {
          let logo = this.logo.image;
          if (!logo) {
            this.makeToast("danger", "Error", `Select a logo to continue!`);
            return;
          }
          this.creatingBrand = true;
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/api/brand/create`,
              {
                name: this.name,
                logo: this.logo,
              },
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              this.creatingBrand = false;
              this.makeToast(
                "success",
                "Success",
                `Brand Created successfully!`
              );
            })
            .catch((error) => {
              console.log(error.response.data);
              this.creatingBrand = false;
              this.makeToast(
                "danger",
                "Error",
                `Error occured while creating brand!`
              );
            });
        }
      });
    },
    handleLogoSelect(media) {
      if (media.status) {
        this.logo.image = media.media.src;
      }
    },
  },
};
</script>

<style scoped>
.media-selecion {
  display: flex;
}

.media-selecion img {
  max-width: 200px;
}
</style>
